import React from 'react'
import CarsHome from '../components/UslugoCars/CarsHome/CarsHome'
import CarsLT from '../components/UslugoCars/CarsLT/CarsLT'

export default function UslugiCars() {
  return (
   <>
  <CarsHome />
  <CarsLT /> 
   </>
  )
}

import React from 'react'
import OpasAbout from '../components/UslugiOpas/OpasAbput/OpasAbout'
import OpasHome from '../components/UslugiOpas/OpasHome/OpasHome'


export default function UslugiOpas() {
  return (
    <>
    <OpasHome />
    <OpasAbout />
    </>
  )
}

import React from 'react'
import OtdeltNews from '../components/OtdelNews/OtdelNews/OtdeltNews'

export default function NewsItdel() {
  return (
    <>
    <OtdeltNews />
    </>
  )
}

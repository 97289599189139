import React from 'react'
import SobrHome from '../components/UslugiSobr/SObrHome/SobrHome'
import SobrText from '../components/UslugiSobr/SobrText/SobrText'

export default function UslugiSobr() {
  return (
   <>
   <SobrHome />
   <SobrText />
   
   </>
  )
}

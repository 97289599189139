import React from 'react'
import OTsleditDostavku from '../components/OtsleditDostavku/OTsleditDostavku'

export default function Dostavka() {
  return (
    <>
      <OTsleditDostavku />
    </>
  )
}

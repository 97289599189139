import React from 'react'
import JDhome from '../components/UslugiJD/JDHome/JDhome'
import JDuslug from '../components/UslugiJD/JDUslugi/JDuslug'

export default function UslugiJd() {
  return (
    <>
    <JDhome />
    <JDuslug/>
    </>
  )
}

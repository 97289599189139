import React from 'react'
import AviaCont from '../components/UslugoAvia/AviaCont/AviaCont'
import AviaHome from '../components/UslugoAvia/AviaHome/AviaHome'

export default function UlugiAvia() {
  return (
    <>
    <AviaHome />
    <AviaCont />
    </>
  )
}

export const ru = {

  // Navbar
    navbar1: "О нас",
    navbar2: "Автомобильные перевозки",
    navbar3: "Авиа перевозки",
    navbar4: "Железнодорожные перевозки",
    navbar5: "Морские перевозки",
    navbar6: "Опасные грузы",
    navbar7: "Сборные грузы",
    navbar8: "Услуги",
    navbar9: "Новости",
    navbar10: "Контакты",
    navbar11: "Отследить доставку",
    navbar12: "Оставить зявку",

  // HOmePage

    modal1: "Обратная связь",
    modal2: "Отправить",

    swiper11: "Доставим ваш груз в любую точку мира",
    swiper12: "Доставляем сборный груз от 1кг по всей стране узнай стоймость перевозки прямо сейчас",

    swiper21: "Морские перевозки",
    swiper22: "Морские перевозки - это наиболее экономичный способ перемещения грузов. Это настолько дешевле, чем железнодорожный или автомобильный вид транспорта - что вы просто удивитесь. Данный раздел посвящен нашим клиентам из Европы, России, Ближнего Востока и Юго-Восточной Азии. Несмотря на то, что наша фирма расположена в Узбекистане, мы профессионально занимаемся морскими перевозками между Юго-Восточной Азией и Европой.  ",

    swiper31: "Железнодорожные перевозки",
    swiper32: "Железнодорожные перевозки - наиболее популярный вид транспортировки грузов у постоянных импортеров и экспортеров. Это связано в основном с низкой стоимостью фрахта и надежностью всей структуры железных дорог, которые в большинстве стран являются естественными государственными монополиями и контролируются соответствующими Государственными ведомствами.",
    
    swiper41: "Авиационные перевозки",
    swiper42: "Авиафрахт - наиболее быстрый способ доставки грузов. Через разветвленную сеть агентов наша фирма имеет возможность организовать авиа перевозки во всех направлениях: Китай, Европа, Россия, США, Ближний Восток. Причем как из Узбекистана, так и в Узбекистан. ",
    
    swiper51: "Опасные грузы",
    swiper52: "Опасный груз - это груз, который в результате транспортного происшествия нанесёт вред здоровью или жизни людей и/или окружающей среде.",
    
    swiper61: "Автомобильные перевозки",
    swiper62: "Узбекистан не имеет выхода к мировому океану. У всех соседних стран также нет морских портов. Поэтому Автомобильные перевозки - наиболее удобный и перспективный вид перевозки из / в Центральную Азию",

    // Types of Log

    types1: "Типы перевозок",
    types12: "Авто перевозки",
    types13: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    types14: "Подробнее",
    types15: "Морские перевозки",
    types16: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    types17: "Опасные грузы",
    types18: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    types19: "Авиаперевозки",
    types20: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    types21: "ЖД перевозки",
    types22: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    types23: "Томоженные услуги",
    types24: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // About Us

    aboutus1: "О нас",
    aboutus2: "AGRO-NL MULTIMODAL LOGISTICS",
    aboutus3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed integer aliquam varius in. Est dui duis mattis elementum. Et id facilisi id sollicitudin. Pharetra leo, quis augue pulvinar sagittis, elit. Diam sapien, nulla vitae commodo, urna, eget pulvinar et ac. Scelerisque commodo faucibus pellentesque cursus odio auctor sed eu. Odio vestibulum montes, tellus euismod vitae sit. Lobortis cursus amet cras elit. Vel lacus semper proin enim rhoncus libero. Sit bibendum nisi nunc eget. Quis etiam elit leo quis nisi, velit varius. Lectus magna nec interdum in amet ornare tempor pulvinar. Duis tellus libero posuere elementum ut phasellus in in. Ut viverra nunc, morbi erat. Amet, nisl orci, bibendum proin egestas gravida scelerisque. Consequat venenatis elit consectetur nulla cum quam. ",

    // Why

    why1 : "Почему стоит выбрать именно нас?",
    why2 : "Таможенное оформление груза",
    why3 : "Страховка груза",
    why4 : "Проверка товара на брак",
    why5 : "Доставка в любой город",
    why6 : "Консолидация на складе",
    why7 : "Ответственное хранение груза",
    why8 : "Помощь в оплате товара",
    why9 : "Упаковка, переупаковка, паллетирование груза к международной перевозке",

    // News

    news1: "Новости и акции",

    // Clients

     clients1: "Наши партнеры",

    //  Contact

    contact1: "Контакты",
    contact2: "Адресс",
    contact3: "Номер",
    contact4: "Почта",

    // Footer

    footer1: "Навигации",
    footer2: "Соц сети",
    footer3: "Клиентам",
    footer4: "г. Ташкент, Мирабадский р-н., ул. Т. Шевченко, 16А",

    // AboutHome

    abouthome1: "Мультимодальная логистическая компания Agro-NL",
    abouthome2: "Мультимодальная логистическая компания Agro-NL - молодая и перспективная команда профессионалов. За короткий период компания приобрела репутацию профессионального и надежного партнера. Наш принцип работы - индивидуальный подход к каждому клиенту.",
    abouthome3: "Каждый человек, обратившийся к нам - становится нашим деловым партнером и другом. В отношении с постоянными деловыми партнерами мы придерживаемся гибкой финансовой политики, предоставляем бесплатные консультации по всем интересующим вопросам.",
    abouthome4: "Наши партнеры",
    abouthome5: "ООО AGRO-NL - официальный агент НАК Узбекистон Хаво Йуллари по продаже грузовых перевозок, сертифицированный транспортный экспедитор с застрахованной ответственностью, зарегистрированный в ГТК таможенный брокер, лицензированный перевозчик и официальный подрядчик Federal Express Corporation в Республике Узбекистан.",
    
    abouthome6: "Основнная деятельность",
    abouthome7: "Компания AGRO-NL осуществляет курьерскую доставку под ключ автомобильными, авиа, железнодорожными и морскими путями, в том числе мультимодальные и сборные грузы.",
    abouthome8: "Мы активно развиваем сферу предоставления своим клиентам полного логистического сервиса по доставке грузов «от двери до двери». Работаем как с юридическими, так и с физическими лицами.",

    abouthome9: "Миссия и цели",
    abouthome10: "Миссия компании Agro-nl — освободить своего клиента от любых задач, связанных с организацией его грузоперевозок, разрешая возникающие сложности на любом этапе доставки груза. Ему нет необходимости самостоятельно продумывать логистический маршрут, заключать несколько договоров с каждым из исполнителей и отслеживать их рейсы",
    abouthome11: "От него требуется только одно — обратиться в нашу компанию, все остальные вопросы по транспортировке груза мы берем на себя.",

    // Contact

    contactClient1: "Контакты",
    contactClient2: "г. Ташкент",
    contactClient3: "Адресс",
    contactClient4: "г. Ташкент, Мирабадский р-н., ул. Т. Шевченко, 16А",
    contactClient5: "Номер",
    contactClient6: "+998 (97) 463-66-88",
    contactClient7: "+998 (90) 155-24-07",
    contactClient8: "support@gmail.com",
    contactClient9: "Почта",
    contactClient10: "обл. Ташкент",
    contactClient11: "Ташкентская обл., Уртачирчикский р-н., Туябугиз, Ш.Бурханов 101",

    contactClient12: "Обратная связь",
    contactClient13: "Отправить",

    // otdelNews

    otdelnews1: "Новостии и акции",
    otdelnews2: "Подробнее",

    // otsleditdostavku

    otsleditdostavku1: "Отследить доставку",
    otsleditdostavku2: "Отследить доставку",
    otsleditdostavku3: "Далее",

    // avtoperevozki

    avto: "Автомобильные перевозки",
    avto1: "Узбекистан не имеет выхода к мировому океану. У всех соседних стран также нет морских портов. Поэтому Автомобильные перевозки - наиболее удобный и перспективный вид перевозки из / в Центральную Азию",
    avto2: "Оставить заявку",
    avto3: "Отследить доставку",
    avto4: "Консолидация / LTL",
    avto5: "Организуем регулярные рейсы из стран Евросоюза и России в Узбекистан, а также в обратном отправлении. Если у Вас нет полной загрузки на фуру, мы все равно сможем принять Ваш груз к перевозке, сгруппировав его с другими неполными загрузками. Для расчета LTL перевозки необходимо знать точные размеры мест, вес, наименование груза, адреса вывоза и доставки.",
    avto6: "Наши преимущество",
    avto7: "Автомобильные перевозки — один из самых оптимальных видов доставки грузов по Узбкуистану: быстро, надежно, приемлемо по цене. К тому же, здесь не нужно согласовывать сроки с расписанием ЖД-, авиа-, морского или речного сообщения — найти, погрузить и отправить фуру, к примеру, можно в любой день и любое время.",
    avto8: "Выбор оптимального маршрута и способа перевозки",
    avto9: "Подбор и фрахтование транспортных средств",
    avto10: "Контроль процесса перевозки и информирование заказчика",
    avto11: "Подбор и фрахтование транспортных средств",
    avto12: "Другие типы перевозок",
    avto13: "Авиаперевозки",
    avto14: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    avto15: "ЖД перевозки",
    avto16: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // aviaperevozki

    avia1: "Авиационные перевозки",
    avia2: "Авиафрахт - наиболее быстрый способ доставки грузов. Через разветвленную сеть агентов наша фирма имеет возможность организовать авиа перевозки во всех направлениях: Китай, Европа, Россия, США, Ближний Восток. Причем как из Узбекистана, так и в Узбекистан. ",
    avia3: "Оставить заявку",
    avia4: "Отследить доставку",
    avia5: "Направления",
    avia6: "Многие компании заключают договоры на поставку авиагрузов на условиях CIP. Не все авиакомпании доставляют груз до районных центров Узбекистана. Мы можем принять груз на свое имя в аэропорту Ташкент, при необходимости выпустить ККДГ (Книжку Контроля Доставки Груза) и перевезти груз до пункта назначения",
    avia7: "ООО AGRO-NL является официальным агентом НАК Узбекистон Хаво Йуллари в Узбекистане. Договор № 50.07/12. Как агент наша компания осуществляет бронирование, оформление и продажу грузовых перевозок в соответствии с требованиями НАК.",
    avia8: "Наши преимущество",
    avia9: "Автомобильные перевозки — один из самых оптимальных видов доставки грузов по Узбкуистану: быстро, надежно, приемлемо по цене. К тому же, здесь не нужно согласовывать сроки с расписанием ЖД-, авиа-, морского или речного сообщения — найти, погрузить и отправить фуру, к примеру, можно в любой день и любое время.",
    avia10: "Выбор оптимального маршрута и способа перевозки",
    avia11: "Подбор и фрахтование транспортных средств",
    avia12: "Контроль процесса перевозки и информирование заказчика",
    avia13: "Подбор и фрахтование транспортных средств",
    avia14: "Другие типы перевозок",
    avia15: "Морские перевозки",
    avia16: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    avia17: "Подробнее",
    avia18: "Автоперевозки",
    avia19: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // jdPerevozki

    jd1: "Железнодорожные перевозки",
    jd2: "Железнодорожные перевозки - наиболее популярный вид транспортировки грузов у постоянных импортеров и экспортеров. Это связано в основном с низкой стоимостью фрахта и надежностью всей структуры железных дорог, которые в большинстве стран являются естественными государственными монополиями и контролируются соответствующими Государственными ведомствами.",
    jd3: "Оставить заявку",
    jd4: "Отследить доставку",
    jd5: "Спекрт услуг",
    jd6: "Наше предприятие может предоставить весь спектр услуг по перевозке грузов железнодорожным транспортом",
    jd7: "Планирование перевозок",
    jd8: "Контейнерные перевозки",
    jd9: "Вагонные перевозки",
    jd10: "Перевозки негабаритных грузов на платформах",
    jd11: "Перегруз на транзитных станциях/ портах",
    jd12: "Документация",
    jd13: "Прослеживание грузов по пути следования",
    jd14: "Все сопутствующие услуги (таможенное оформление, страхование, экспедирование, подготовка необходимых транспортных документов)",
    jd15: "Импортерам",
    jd16: "Для Импортеров имеется налаженный канал поставки грузов из Китая, Кореи, ОАЭ, Турции и стран Европы. По Вашему запросу мы можем организовать консолидацию груза и подготовку экспортных документов в стране отправления. Наши агенты могут взять на себя оформление экспортных формальностей.",
    jd17: "Другие типы перевозок",
    jd18: "Морские перевозки",
    jd19: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    jd20: "Подробнее",
    jd21: "Автоперевозки",
    jd22: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // morskperevozki

    morsk1: "Морские перевозки",
    morsk2: "Морские перевозки - это наиболее экономичный способ перемещения грузов. Это настолько дешевле, чем железнодорожный или автомобильный вид транспорта - что вы просто удивитесь. Данный раздел посвящен нашим клиентам из Европы, России, Ближнего Востока и Юго-Восточной Азии. Несмотря на то, что наша фирма расположена в Узбекистане, мы профессионально занимаемся морскими перевозками между Юго-Восточной Азией и Европой.",
    morsk3: "Оставить заявку",
    morsk4: "Отследить доставку",
    morsk5: "Направления",
    morsk6: "В наши дни морские перевозки занимают лидирующее положение по объемам перевозимых грузов. Тысячи огромных сухогрузов бороздят мировой океан, перевозя свыше половины всех грузов на земле. Стоимость перевозки грузов по морю в два-три раза дешевле чем по железной дороге. Узбекистан не имеет выхода к мировому океану. Морские перевозки из/ в Узбекистан - обязательно комбинируются с железнодорожными или автомобильными перевозкам",
    morsk7: "Наша компания имеет надежных агентов в портах таких стран, как",
    morsk8: "Будучи участниками Всемирного Альянса Экспедиторов, мы предложим Вам самые низкие ставки на морские контейнерные перевозки. Грузим на первоклассные морские линии с возможностью онлайн отслежки ваших грузов. Выполним все связанные с перевозкой услуги, такие как подача контейнера, терминальная обработка, хранение, таможенное оформление, страхование и многое другое.",
    morsk9: "Другие типы перевозок",
    morsk10: "Авиаперевозки",
    morsk11: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    morsk12: "Подробнее",
    morsk13: "ЖД перевозки",
    morsk14: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // opasgruz

    opas1: "Опасные грузы",
    opas2: "Опасный груз - это груз, который в результате транспортного происшествия нанесёт вред здоровью или жизни людей и/или окружающей среде.",
    opas3: "Оставить заявку",
    opas4: "Отследить доставку",
    opas5: "В Мире действуют следующие договоры перевозки опасных грузов:",
    opas6: "IMDG (International Maritime Dangerous Goods) - договор по перевозке опасных грузов морским транспортом.",
    opas7: "ICAO-TI (Technical Instructions for The Safe Transport of Dangerous Goods by AIR) – договор по перевозке опасных грузов авиатранспортом.",
    opas8: "RID (International Regulations Concerning the Carriage of Dangerous Goods by Rail) – о перевозке опасных грузов железнодорожным транспортом.",
    opas9: "Создан специальный список опасных веществ ООН (более 3000 наименований). Каждое опасное вещество, внесённое в этот список, имеет четырёхзначный номер, который называется идентификационным номером по списку опасных веществ ООН. По этому номеру можно узнать точное название перевозимого опасного вещества.",
    opas10: "Список разбит по классам - разрешено опасное вещество одного класса перевозить, только по предназначенным для него правилам. Эти классы образованы по:",
    opas11: "Главной опасности вещества во время его транспортировки.",
    opas12: "Физическое состояние вещества во время транспортировки (твёрдое, жидкое, газообразное).",
    opas13: "Весь список опасных веществ разбит на 13 классов. Нумерация от 1 до 9.",
    opas14: "Виды опасностей:",
    opas15: "Взрывоопасность",
    opas16: "Огнеопасность, Ядовитость (токсичность)",
    opas17: "Ядовитость (токсичность)",
    opas18: "Едкость (коррозийность)",
    opas19: "Радиоактивность",
    opas20: "Окисление (свойства способствующие горению)",
    opas21: "Грузы делятся на классы опасности в соответствии с классификацией договора ADR:",
    opas22: "1 класс - взрывчатые материалы и предметы;",
    opas23: "2 класс - сжатые, сжиженные и растворённые под давлением газы;",
    opas24: "3 класс - легко воспламеняющиеся жидкости;",
    opas25: "4.1 класс - легко воспламеняющиеся твёрдые вещества;",
    opas26: "4.2 класс - самовозгорающиеся вещества;",
    opas27: "4.3 класс - вещества, выделяющие воспламеняющиеся газы при соприкосновении с водой;",
    opas28: "5.1 класс - окисляющие вещества;",
    opas29: "5.2 класс - органические пероксиды;",
    opas30: "6.1 класс - ядовитые вещества;",
    opas31: "6.2 класс - побочные продукты переработки животных и инфекционные вещества;",
    opas32: "7 класс - радиоактивные материалы;",
    opas33: "8 класс - коррозирующие вещества;",
    opas34: "9 класс - прочие опасные вещества.",
    opas35: "Другие типы перевозок",
    opas36: "Авиаперевозки",
    opas37: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    opas38: "Подробнее",
    opas39: "ЖД перевозки",
    opas40: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // sborniyegruzi

    sbor1: "Перевозка сборных грузов",
    sbor2: "Для перевозки сборных грузов используются морской, железнодорожный, автомобильный и авиатранспорт. Для каждого Клиента команда AsstrA организует подходящую схему транспортировки, учитывая персональные потребности и характеристики груза.",
    sbor3: "Оставить заявку",
    sbor4: "Отследить доставку",
    sbor5: "Корпоративным клиентам",
    sbor6: "Международная группа компаний AGRO-NL Multimodal Logistics предоставляет корпоративным Клиентам полный перечень транспортно-логистических услуг для перевозки мелкогабаритных грузов. Продукция доставляется от нескольких отправителей на одном транспортном средстве в одном направлении. Благодаря рациональному использованию грузового пространства снижаются транспортные издержки. Опытная команда AsstrA анализирует адресные запросы Клиентов, учитывает свойства перевозимого товара и составляет проверенный маршрут перевозки сборных грузов по странам СНГ, Европейского Союза и Средней Азии.",
    sbor7: "Виды транспорта",
    sbor8: "Для перевозки сборных грузов используются морской, железнодорожный, автомобильный и авиатранспорт. Для каждого Клиента команда AsstrA организует подходящую схему транспортировки, учитывая персональные потребности и характеристики груза. ",
    sbor9: "Возможности перевозки",
    sbor10: "Доставка грузов «от двери до двери» в странах Европы. ",
    sbor11: "Еженедельные линии доставки сборных грузов по направлению ЕС – СНГ, Украина, Средняя Азия.",
    sbor12: "Консолидационные склады в Германии, Италии, Литве, Польше, Беларуси. ",
    sbor13: "Экспресс-доставки грузов отдельными малотоннажными автомобилями по европейскому региону. ",
    sbor14: "Комбинированные схемы доставки сборных грузов из Китая в страны Европы.",
    sbor15: "Контроль за перевозкой на каждом участке маршрута.",
    sbor16: "Оформление товаросопроводительных и таможенной документации. Страхование грузов «от всех рисков».",
    sbor17: "Другие типы перевозок",
    sbor18: "Морские перевозки",
    sbor19: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",
    sbor20: "Подробнее",
    sbor21: "Автоперевозки",
    sbor22: "Принимаем, сопровождаем и сдаем груз по утвержденному маршруту. Контролируем правильное оформление...",

    // Dostavka

    dostavka1: "Введить ID номер",
  };


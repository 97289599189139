import React from 'react'
import './homePage.css';
import SwiperHome from './Swiper/Swiper';

export default function HomePage() {
    return (
        <>
            <div className="homePage">
                <SwiperHome />
            </div>

        </>
    )
}

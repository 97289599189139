import React from 'react'
import './newUslugi.css'
import {GiCommercialAirplane, GiCarWheel, GiShipBow, GiCargoCrate} from 'react-icons/gi'
import {BiTrain} from 'react-icons/bi'
import {MdDangerous} from 'react-icons/md'
import { getText  } from '../../locales'
import { Link } from 'react-router-dom'
export default function NewUslugi() {
  return (
    <>
    <nav class="social">
                    <ul className='ul_icon'>
                        <li className='li_icon'><Link to='/truck'><GiCarWheel className='rect_icons_for_menu' />{getText("navbar2")}</Link></li>
                        <li className='li_icon'><Link to='/avia'><GiCommercialAirplane className='rect_icons_for_menu' />{getText("navbar3")}</Link></li>
                        <li className='li_icon'><Link to='/jd'><BiTrain className='rect_icons_for_menu' />{getText("navbar4")}</Link></li>
                        <li className='li_icon'><Link to='/morsk'><GiShipBow className='rect_icons_for_menu' />{getText("navbar5")}</Link></li>
                        <li className='li_icon'><Link to='/opasniye'><MdDangerous className='rect_icons_for_menu' />{getText("navbar6")}</Link></li>
                        <li className='li_icon'><Link to='/sobr'><GiCargoCrate className='rect_icons_for_menu' />{getText("navbar7")}</Link></li>
                    </ul>
                </nav>
    </>
  )
}
